<template>
  <div class="out" ref="out">
    <div class="header">
      <div class="logo Title">

        <div class="Chinese"><img src="../assets/img/return_index.png" class="return1" alt=""
            @click="returnSearch" />{{is_inst == 1 ? "收藏机构信息":$t('main.masterInfo')}}</div>
      </div>
      <div class="right-icon">
        <img src="../assets/img/logo-icon.png" alt="" />
        
      </div>
    </div>

    <div class="pr_top">
      <div class="pr_pr">
        <img src="../assets/img/wzw_vip.png" alt />
        {{name}}专属私人定制
      </div>
    </div>
    <div class="pr_teacher">
      <div class="pr_teacher_top">
        <div class="pr_tt_avatar">
            <div>
              <img v-lazy="(pic + '?width=216')" alt="">
            </div>
        </div>
        <div class="pr_tt_info">
            <div>{{name}}</div>
            <div>{{ title }}</div>
        </div>
        <div class="pr_tt_follow" @click="concern" :style="follow_status == 0 ? 'color:#000;':'color:#929292;'">{{follow_status == 0 ? '+ 关注' : $t('main.followed')}}</div>
      </div>
      <div class="pr_t_detail">
        <div style="font-size:0.14rem" :class="[JSmore ? '' : 'noMore']" v-html="content"></div>
        <div class="pr_show_more" v-if="is_user_content" @click="JSmore = !JSmore">
            {{ JSmore ? $t('main.putaway') : $t('main.seemore') }}
        </div>
      </div>
      <div class="pr_t_action">
        <div class="icon-logo"  @click="actionS()">
              <div><img  src="../assets/img/share_icon.png" alt=""></div>
              <div  class="title title-color">{{$t('main.share')}}</div>
        </div>
        <div class="icon-logo" @click="DownLoadQRcode">
              <div>
              <img src="../assets/img/QRcode-logo.png" alt="" >
              </div>
              <div  class="title title-color">{{$t('main.qrcode')}}</div>
        </div>
      </div>
    </div>
    <div class="pr_item">
        <div class="pr_t_honor">
            <div class="pr_t_h_top">
                <img src="../assets/img/wzw_honor.png" alt />
                作者荣誉
            </div>
            <div class="pr_t_h_h" :class="[HYmore ? '' : 'noMore']" v-html="honor"></div>
            <div  class="showMore" v-if="is_user_honor" @click="HYmore = !HYmore">
                {{ HYmore ? $t('main.putaway') : $t('main.seemore') }}
            </div>
        </div>
    </div>
    <div class="pr_item">
      <div class="pr_nav">
        <div :class="is_te_show?'active':''" @click="is_te_show = true">名家珍宝({{tuijian_total}})</div>
        <div :class="is_te_show?'':'active'" @click="is_te_show = false">个人新闻({{newList.length}})</div>
      </div>
      <div class="pr_good_item" v-show="is_te_show">
      
        <div class="pr_good_item_i" v-for="(item, index) in tuijian" :key="index" @click="goDetail(item)" >
          <img v-lazy="(item.pictures[0] + '?width=216')" alt />
          <div>{{ item.name }}</div>
          <div>估值：￥<span style="font-size:0.15rem;font-weight:bold">{{ thousands(item.price) }}</span></div>
          <div>{{ item.description }}</div>
        </div>

        <div class="the_end" >{{hasMorePages?'':'一 这是艺术的底线 一'}}</div>
      </div>
      <div class="pr_new_list" v-show="!is_te_show">
         <!-- -->
        <div class="pr_new_item" v-for="(item, index) in newList" :key="index"  @click="gourl(item.url)">
          <div>
            <img src="../assets/img/new_img.png" alt="">
          </div>
          <div class="pr_new_item_info">
            <div>{{item.title}}</div>
            <div>{{item.updated_at}}</div>
          </div>
        </div>
        <div class="the_end">一 这是艺术的底线 一</div>
      </div>
    </div>
    <div class="text_bottom">
      <div class="report" >
        <div style="display:flex;align-items:center" @click="clickReport">
        <img src="../assets/img/report-icon.png" alt=""> {{ $t('main.report') }}
        </div>
      </div>
      <span style="color: #b6b6b6">{{ $t('main.copyacon') }}<br /></span>
      {{ $t('main.tppswsc') }}<br />
      {{ $t('main.platTips') }}<br />
      <span style="color: #b6b6b6">{{ $t('main.noaldar') }}</span>
    </div>
    <privacy ref="privacy"></privacy>
    <div class="speak_fixed" v-show="false">
      <div class="AR-Read" v-if="audioStatus == 2">
        <img
          class="zanting"
          v-show="!isShowARbox"
          @click="bofangAR"
          src="../assets/img/aiyy-zanting.png"
          alt
        />
        <div class="AR-bofang" v-show="isShowARbox">
          <div class="AR-box">
            <img
              src="../assets/img/aiyy-zanting.png"
              v-show="speakShowRead == 2"
              @click="speakRead"
              alt
            />
            <img
              src="../assets/img/aiyy-bofang.png"
              v-show="speakShowRead == 1"
              @click="speakRead"
              alt
            />
            <div class="tiao-wrap">
              <van-slider
                @change="changeEnd"
                v-model="valueLeangth"
                button-size="12px"
                bar-height="2px"
                active-color="#210FA6"
                inactive-color="#B6AEDC"
              />
            </div>
            <div class="time">{{durationTime}}</div>
            <div class="guanbi" @click="guanbi">
              <img src="../assets/img/guanbi-aryy-ccc.png" alt />
            </div>
          </div>
        </div>
      </div>
      <audio controls ref="audio" class="aud" :src="audioSrc" @ended="resetSpeak"></audio>
    </div>
    <div class="share_pop pop" v-show="share_pop" >
      <div class="share_top">
        <img src="../assets/img/share_top.png" alt="">
      </div>
      <div class="share_bottom" @click="changeSharePop">
        <img src="../assets/img/share_close.png" alt="">
      </div>
    </div>
    <div
      class="pop pop-cy-rz"
      v-show="qrcode1"
      @click="closeQr1"
    >
      <div class="pop-qrcode">
      <div class="">
        <img
          :src="nowZS"
          alt=""
        />
      </div>
	    <div class="pop-tishi"><img src="../assets/img/click-logo-zm.png" alt=""> {{$t('main.lodqr')}}</div>
      </div>
    </div>
     <img src="../assets/img/border_qrcode.png" id="border" style="display:none" alt="">
    <img src="../assets/img/qrcode_name_3.png" id="name" style="display:none" alt="">
  </div>
</template>

<script>
import { ImagePreview, Dialog, Toast, Slider } from "vant";
import QRCode from "qrcodejs2";
import changelang from "../components/changelangtype.vue";
import privacy from "../components/privacy.vue";
export default {
  components: {
    changelang,
    privacy
  },
  watch: {
    "$i18n.locale"(newVal, oldVal) {
      if (newVal == oldVal) {
        return;
      }
      if (this.$route.query.locale) {
        if (!this.isloading) {
          this.$router.go(0);
        }
        this.isloading = false;
      } else {
        this.$router.go(0);
      }
    }
  },
  data() {
    return {
      tuijian_show: true,
      tuijian: [
        {
          description: "",
          id: "",
          level: {
            name: ""
          },
          level_id: "",
          name: "",
          pictures: [],
          price: ""
        }
      ],
      ifName: "",
      is_user_content: false,
      is_user_honor: false,
      help: {
        goods_price: null,
        holder_certification: null,
        goods_number: null,
        goods_hash: null,
        goods_levels: null,
        goods_create_time: null,
        personal_profile: null,
        author_honor: null,
        author_news: null,
        want_buy: null
      },
      content: "",
      created_at: "",
      honor: "",
      id: 0,
      is_invent: "",
      is_recommend: "",
      is_reset: "",
      name: "",
      pic: "",
      recommend_auto: "",
      recommend_expire: null,
      score: "",
      sign_password: "",
      status: "",
      title: "",
      type: "",
      updated_at: "",
      verify_times: "",
      is_user_content: false,
      scjFlag: false,
      JSmore: false,
      HYmore: false,
      showLoading: false,
      page: 1,
      loading1: false,
      hasMorePages: true,
      newList: [],
      isScan: false,
      arrImage: [],
      certificate_info: [],
      current: 0,
      share_pop: false,
      qrcode1: false,
      nowZS: "",
      speakShowRead: 0,
      audioSrc: "",
      lodAudioSrc: "",
      audioStatus: "",
      durationTime: "00:00",
      isShowARbox: false,
      valueLeangth: 0,
      follow_status: 0,
      collector_id: 0,
      shouchang: 0,
      birthdayLeangth: 0,
      gender: "",
      birthday: "",
      isloading: true,
      is_inst: 0,
      hasHead: false,
      is_te_show:true,
      tuijian_total:0
    };
  },
  destroyed() {
    if (this.hasHead) {
      let callStyle = document.querySelector(
        "style[data-callType='callStyle']"
      );
      document.head.removeChild(callStyle);
    }
  },
  async mounted() {
    if (this.$route.query.__params) {
      let json = JSON.parse(this.$base64.decode(this.$route.query.__params));

      let query = json;
      this.$route.params.id = query.id;
    }
    this.$refs.out.addEventListener('scroll',this.throttle(this.scrollFn,500))
    this.$refs.audio.oncanplay = () => {
      var time = this.$refs.audio.duration;
      //分钟
      if (!time) {
        return;
      }
      var minute = time / 60;
      var minutes = parseInt(minute);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      //秒
      var second = time % 60;
      var seconds = Math.round(second);
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      this.durationTime = minutes + " : " + seconds;
      this.currentLeanth = this.$refs.audio.currentTime;
      this.durationLeanth = this.$refs.audio.duration;
    };
    this.$refs.audio.ontimeupdate = () => {
      if (!this.$refs.audio.duration) {
        return;
      }
      let length = (this.$refs.audio.currentTime / this.durationLeanth) * 100;
      this.valueLeangth = length;

      var time = this.$refs.audio.duration - this.$refs.audio.currentTime;

      var minute = time / 60;
      var minutes = parseInt(minute);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      //秒
      var second = time % 60;
      var seconds = Math.round(second);
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      this.durationTime = minutes + " : " + seconds;
    };
    if (this.$route.query.locale) {
      this.$i18n.locale = this.$route.query.locale;
      window.localStorage.setItem("lang", this.$route.query.locale);
      let nowUrl = this.$route.fullPath.split("?")[0];
      if(this.$route.query.__params){
        nowUrl = nowUrl + '?__params='+this.$route.query.__params
      }
      
      window.location.replace(nowUrl);
    }
    let token = localStorage.getItem("user_token");
    if (this.$route.query.code && !token) {
      let querys = "";
      if (this.$route.query.phone) {
        let phone = this.getQueryString("phone");
        let nickName = this.getQueryString("phone_code");
        querys = `&phone=${phone}&phone_code=${decodeURIComponent(nickName)}`;
      }
      await this.axios
        .get("/auth/nt?code=" + this.$route.query.code + querys)
        .then(res => {
          if (res.data.code == -1 && res.data.msg == "请填写手机号") {
            this.$refs.privacy.openPopup();
            return;
          }
          window.localStorage.setItem("user_token", res.data.data.token);
        });

      history.replaceState(
        null,
        null,
        "/tcdetail/id" + "?__params=" + this.$route.query.__params
      );
    }

    this.isScan = !!this.$route.query.status;

    // this.$refs.out.addEventListener(
    //   "scroll",
    //   this.throttle(this.scrollFn, 500)
    // );
    let artist_id = "";
    await this.axios
      .post("common/encrypt", {
        artist_id: this.$route.params.id,
        more: 0,
        count: 4,
        page: 1
      })
      .then(res => {
        artist_id = res.data.data.data_encrypted;
      });
    this.axios.get("/goods/recommend?data=" + artist_id).then(res => {
      if (res.data.data.items && this.$i18n.locale == "en") {
        for (let i = 0; i < res.data.data.items.length; i++) {
          res.data.data.items[i].name = res.data.data.items[i].name_intl
            ? res.data.data.items[i].name_intl
            : res.data.data.items[i].name;
          res.data.data.items[i].description = res.data.data.items[i]
            .description_intl
            ? res.data.data.items[i].description_intl
            : res.data.data.items[i].description;
        }
      }
      this.tuijian = res.data.data.items || [];
      this.tuijian_total = res.data.data.total
      // if (this.tuijian.length == 1) {
      //   if (this.tuijian[0].id == this.$route.params.id)
      //     this.tuijian_show = false;
      // }
      if (this.tuijian.length < 1) {
        this.tuijian_show = false;
      }
    });

    this.axios.get("/news/list/" + this.$route.params.id).then(res => {
      if (res.data.data.list) {
        if (this.$i18n.locale == "en") {
          for (let i = 0; i < res.data.data.list.length; i++) {
            if (res.data.data.list[i].title_intl) {
              res.data.data.list[i].title = res.data.data.list[i].title_intl;
            }
          }
        }
        this.newList = res.data.data.list;
      } else {
        this.newList = [];
      }
    });
    let data = "";
    await this.axios
      .post("common/encrypt", { id: this.$route.params.id })
      .then(res => {
        data = res.data.data.data_encrypted;
      });
    await this.axios.post("/artist/detail", { data: data }).then(res => {
      if (this.$i18n.locale == "en") {
        let data = res.data.data;
        Object.keys(data).forEach(item => {
          if (item.indexOf("_intl") != -1) {
            let label = item.split("_intl")[0];
            if (res.data.data[item]) {
              res.data.data[label] = res.data.data[item];
            }
          }
        });
      }
      this.certificate_info = res.data.data.certificate_info
        ? res.data.data.certificate_info
        : [];
      this.content = res.data.data.content;
      this.created_at = res.data.data.created_at;
      this.honor = res.data.data.honor
        ? res.data.data.honor.replace(/(\r\n|\n|\r)/gm, " <br /> ")
        : "";
      this.id = res.data.data.id;
      this.is_invent = res.data.data.is_invent;
      this.is_recommend = res.data.data.is_recommend;
      this.is_reset = res.data.data.is_reset;
      this.name = res.data.data.name;
      this.is_inst = res.data.data.is_collector_inst;
      this.birthday = res.data.data.birthday
        ? res.data.data.birthday.split(" ")[0]
        : "";
      if (res.data.data.gender != 0) {
        this.gender =
          res.data.data.gender == 1
            ? this.$t("main.male")
            : this.$t("main.female");
      }
      this.birthdayLeangth = res.data.data.age;

      this.pic = res.data.data.pic;
      this.recommend_auto = res.data.data.recommend_auto;
      this.recommend_expire = res.data.data.recommend_expire;
      this.score = res.data.data.score;
      this.sign_password = res.data.data.sign_password;
      this.status = res.data.data.status;
      this.title = res.data.data.title;
      this.type = res.data.data.type;
      this.updated_at = res.data.data.updated_at;
      this.verify_times = res.data.data.verify_times;

      if (this.type == 2) {
        this.scjFlag = true;
      }
      let test = document.createElement("div");
      test.style.width = "3.135rem";
      test.style.fontSize = "0.15rem";
      test.innerText = "测试";
      document.body.appendChild(test);
      let test_height = test.clientHeight;

      document.body.removeChild(test);
      let desList = res.data.data.description
        ? res.data.data.description.split("\n")
        : [];
      for (let i = 0; i < desList.length; i++) {
        if (i == 0) {
          this.description = desList[i];
          continue;
        }
        this.description = this.description + "<br>" + desList[i];
      }

      let el = document.createElement("div");
      el.style.width = "3.135rem";
      el.style.fontSize = "0.15rem";
      el.innerHTML = this.description;
      document.body.appendChild(el);
      if (el.clientHeight / test_height > 5) {
        this.is_description = true;
      }
      document.body.removeChild(el);

      let conList = this.content ? this.content.split("\n") : [];
      for (let i = 0; i < conList.length; i++) {
        if (i == 0) {
          this.content = conList[i];
          continue;
        }
        this.content = this.content + "<br>" + conList[i];
      }

      let el1 = document.createElement("div");
      el1.style.width = "3.46rem";
      el1.style.fontSize = "0.15rem";
      el1.innerHTML = this.content;
      document.body.appendChild(el1);
      if (el1.clientHeight / test_height > 2) {
        this.is_user_content = true;
      }

      document.body.removeChild(el1);

      let userhonList = this.honor ? this.honor.split("\n") : [];
      for (let i = 0; i < userhonList.length; i++) {
        if (i == 0) {
          this.honor = userhonList[i];
          continue;
        }
        this.honor = this.honor + "<br>" + userhonList[i];
      }
      let el2 = document.createElement("div");
      el2.style.width = "3.46rem";
      el2.style.fontSize = "0.15rem";
      el2.innerHTML = this.honor;

      document.body.appendChild(el2);
      if (el2.clientHeight / test_height > 2) {
        this.is_user_honor = true;
      }

      document.body.removeChild(el2);
    });
    token = localStorage.getItem("user_token");
    if (token) {
      this.axios
        .post("/artist/getFollowStatus", { artist_id: this.$route.params.id })
        .then(res => {
          this.follow_status = res.data.data.follow_status
            ? res.data.data.follow_status
            : 0;
        });
      this.axios.get("/auth/me").then(res => {
        this.collector_id = res.data.data.user.artist_id;
        this.shouchang = res.data.data.user.collector_id;
      });
    }
    let url = location.protocol + "//" + location.host;
    this.axios
      .post("/auth/wechatConfig", {
        url: url + this.$store.state.jssdkUrl,
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "onMenuShareAppMessage",
          "onMenuShareTimeline"
        ]
      })
      .then(res => {
        let json = JSON.parse(res.data.data.data);

        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: json.appId, // 必填，公众号的唯一标识
          timestamp: json.timestamp, // 必填，生成签名的时间戳
          nonceStr: json.nonceStr, // 必填，生成签名的随机串
          signature: json.signature, // 必填，签名
          jsApiList: json.jsApiList // 必填，需要使用的 JS 接口列表
        });
      });
    let newdata = "";
    await this.axios
      .post("common/encrypt", { artist_id: this.$route.params.id })
      .then(res => {
        newdata = res.data.data.data_encrypted;
      });
    this.axios.post("/artist/getAudioStatus", { data: newdata }).then(res => {
      this.audioSrc =
        this.$i18n.locale == "en"
          ? res.data.data.audio_intl
          : res.data.data.audio;
      this.audioStatus =
        this.$i18n.locale == "en"
          ? res.data.data.audio_status_intl
          : res.data.data.audio_status;
    });
    let locale =
      this.$route.fullPath.indexOf("?") == -1
        ? "?locale=" + this.$i18n.locale
        : "&locale=" + this.$i18n.locale;
      console.log(this.$route.fullPath)
    wx.updateAppMessageShareData({
      title: this.name, // 分享标题
      desc: "—— 长城文创珍宝中心\n" + this.title, // 分享描述
      link: url + this.$route.fullPath + locale, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
      imgUrl: (this.pic + "?width=200").replace(
        /((^[A-Za-z]{4,5})+\:)/,
        location.protocol
      ), // 分享图标
      success: () => {
        // this.axios.get(`goods/share/${this.$route.params.id}`).then((res)=>{
        //   this.share_num++
        // })
      }
    });
    wx.updateTimelineShareData({
      title: this.name, // 分享标题
      desc: "—— 长城文创珍宝中心\n" + this.title, // 分享描述
      link: url + this.$route.fullPath + locale, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
      imgUrl: (this.pic + "?width=200").replace(
        /((^[A-Za-z]{4,5})+\:)/,
        location.protocol
      ), // 分享图标
      success: () => {
        // this.axios.get(`goods/share/${this.$route.params.id}`).then((res)=>{
        //   this.share_num++
        // })
      }
    });
    wx.ready(() => {
      wx.onMenuShareAppMessage({
        title: this.name, // 分享标题
        desc: "—— 长城文创珍宝中心\n" + this.title, // 分享描述
        link: url + this.$route.fullPath + locale, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: (this.pic + "?width=200").replace(
          /((^[A-Za-z]{4,5})+\:)/,
          location.protocol
        ), // 分享图标
        type: "link", // 分享类型,music、video或link，不填默认为link
        success: () => {
          // this.axios.get(`goods/share/${this.$route.params.id}`).then((res)=>{
          //   this.share_num++
          // })
        }
      });
      wx.onMenuShareTimeline({
        title: this.name + "--" + this.name, // 分享标题
        desc: "—— 长城文创珍宝中心\n" + this.title, // 分享描述
        link: url + this.$route.fullPath + locale, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: (this.pic + "?width=200").replace(
          /((^[A-Za-z]{4,5})+\:)/,
          location.protocol
        ), // 分享图标
        type: "link", // 分享类型,music、video或link，不填默认为link
        success: () => {
          // this.axios.get(`goods/share/${this.$route.params.id}`).then((res)=>{
          //   this.share_num++
          // })
        }
      });
    });
  },

  methods: {
     changeSharePop(){
      if(!this.$isWeiXin()){
				weui.alert('请在微信浏览器打开')
				return
			}
      this.share_pop = !this.share_pop
    },
    showContent(key) {
      Dialog.alert({ message: this.help[key], confirmButtonColor: "#2d71d7" });
    },
    goDetail(item) {
      if (item.status == 2) {
        return;
      } else if (item.is_show == 0) {
        weui.alert(this.$t("main.trisbanpc"), {
          buttons: [{ label: this.$t("main.confIrm") }]
        });
        return;
      }
      let id = item.goods_id ? item.goods_id : item.id;
      this.$router.push(`/detail/${id}?status=1`);
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return decodeURIComponent(r[2]);
      return null;
    },
    // goDetail(item) {
    //   console.log(item)
    // },
    returnSearch() {
      if (this.isScan) {
        this.$router.replace("/index");
      } else {
        this.$router.back();
      }
    },
    gourl(url) {
      window.location.href = url;
    },
    thousands(num) {
      var splits = [],
        res = [];
      var splits = num.toString().split(".");
      splits[0]
        .split("")
        .reverse()
        .map(function(item, i) {
          if (i % 3 == 0 && i != 0) {
            res.push(",");
          }
          res.push(item);
        });
      return (
        res.reverse().join("") + (splits.length > 1 ? "." + splits[1] : "")
      );
    },
    throttle(fn, time) {
      let canRun = true;
      return function() {
        if (!canRun) return;
        canRun = false;
        setTimeout(() => {
          fn.apply(this);
          canRun = true;
        }, time);
      };
    },
    changIndexx(index) {
      this.$refs.myswipe.swipeTo(index);
    },

    changeAct(index) {
      this.arrImage = [];
      this.certificate_info.forEach(item => {
        this.arrImage.push(item.pic);
      });
      ImagePreview({
        images: this.arrImage,
        startPosition: this.current,
        closeable: true
      });
    },

    swipechange(e) {
      this.current = e;
    },
    async scrollFn(e) {
      
       if (this.$refs.out.scrollHeight - this.$refs.out.scrollTop - this.$refs.out.clientHeight <= 20) {
        if (this.hasMorePages && !this.loading1) {
          this.showLoading = true;
          const count = 4;
          this.page++;
          this.loading1 = true;
          let artist_id = "";
          await this.axios
            .post("common/encrypt", {
              artist_id: this.$route.params.id,
              count: count,
              page: this.page
            })
            .then(res => {
              artist_id = res.data.data.data_encrypted;
            });
          this.axios.get("/goods/recommend?data=" + artist_id).then(res => {
            this.showLoading = false;
            this.hasMorePages = res.data.data.hasMorePages;
            this.loading1 = false;
            if (res.data.data.items && this.$i18n.locale == "en") {
              for (let i = 0; i < res.data.data.items.length; i++) {
                res.data.data.items[i].name = res.data.data.items[i].name_intl
                  ? res.data.data.items[i].name_intl
                  : res.data.data.items[i].name;
                res.data.data.items[i].description = res.data.data.items[i]
                  .description_intl
                  ? res.data.data.items[i].description_intl
                  : res.data.data.items[i].description;
              }
            }
            res.data.data.items.forEach(item => {
              {
                this.tuijian.push(item);
              }
            });
          });
        
        }
       }
    },
    actionS() {
      if(!this.$isWeiXin()){
				weui.alert('请在微信浏览器打开')
				return
			}
      this.share_pop = !this.share_pop;
      // weui.actionSheet([
      //   {
      //     label: this.$t('main.downqr'),
      //     onClick: () => {
      //       this.DownLoadQRcode();
      //     },
      //   },
      //   {
      //     label: "分享给朋友或朋友圈",
      //     onClick: () => {
      //       this.share_pop = !this.share_pop
      //     },
      //   },
      // ]);
    },
    closeQr1() {
      this.qrcode1 = false;
    },

    speakRead() {
      if (this.speakShowRead != 0) {
        if (this.$refs.audio.paused) {
          this.$refs.audio.play();
          this.speakShowRead = 1;
        } else {
          this.$refs.audio.pause();
          this.speakShowRead = 2;
        }
      } else {
        if (this.$refs.audio.src == this.lodAudioSrc) {
          this.$refs.audio.load();
          this.$refs.audio.play();
          this.speakShowRead = 1;
        } else {
          this.$refs.audio.src = this.audioSrc;
          this.lodAudioSrc = this.audioSrc;
          this.$refs.audio.play();
          this.speakShowRead = 1;
        }
      }
    },
    changeEnd(value) {
      this.$refs.audio.currentTime = (value * this.durationLeanth) / 100;

      if (this.$refs.audio.paused) {
        this.$refs.audio.play();
        this.speakShowRead = 1;
      }
    },
    bofangAR() {
      this.$refs.audio.play();
      this.speakShowRead = 1;
      this.isShowARbox = !this.isShowARbox;
    },
    guanbi() {
      this.$refs.audio.pause();
      this.isShowARbox = !this.isShowARbox;
    },
    speakRead() {
      if (this.$refs.audio.paused) {
        this.$refs.audio.play();
        this.speakShowRead = 1;
      } else {
        this.$refs.audio.pause();
        this.speakShowRead = 2;
      }
    },

    resetSpeak() {
      this.isShowARbox = false;
    },
    clickReport() {
      if(!this.$isWeiXin()){
				weui.alert('请在微信浏览器打开')
				return
			}
      this.$router.push({
        path: "/report/" + this.id,
        query: {
          accuse_pattern: 1
        }
      });
    },
    DownLoadQRcode() {
      let url = location.protocol + "//" + location.host; //http://192.168.1.81:8080
      let div = document.createElement("div");
      let code = new QRCode(div, {
        text: url + "/tcdetail/" + this.$route.params.id + "?status=1", //要生成二维码的网址
        width: 410, //图像宽度
        height: 410, //图像高度
        colorDark: "#000000", //前景色
        colorLight: "#ffffff", //背景色
        margin: 20, //外边距
        correctLevel: QRCode.CorrectLevel.L //容错级别。属性值有：QRCode.CorrectLevel.L、QRCode.CorrectLevel.M、QRCode.CorrectLevel.Q、QRCode.CorrectLevel.H
      }); // div canvas
      let canvas = code._el.querySelector("canvas"); //获取生成二维码中的canvas，并将canvas转换成base64
      let qr_ctx = canvas.getContext("2d");
      let qr_img = qr_ctx.getImageData(0, 0, canvas.width, canvas.height); //获取二维码图片数据

      var canvas1 = document.createElement("canvas");
      var ctx = canvas1.getContext("2d");
      var border = document.getElementById("border");

      canvas1.width = border.width;
      canvas1.height = border.height;
      ctx.drawImage(border, 0, 0, canvas1.width, canvas1.height);
      var name = document.getElementById("name");

      ctx.font = "25px Arial";
      ctx.fillStyle = "#fcf6dc";
      ctx.textAlign = "center";
      if (ctx.measureText(this.name).width < name.width - 50) {
        ctx.drawImage(name, 290, 35, name.width, name.height);

        ctx.fillText(this.name, 290 + name.width / 2, 67); //姓名
      } else {
        ctx.drawImage(name, 240, 35, 200, 50);

        ctx.fillText(this.name, 340, 67);
      }
      ctx.fillStyle = "#FFFFFF";
      ctx.fillRect(30, 100, 430, 430);
      ctx.putImageData(qr_img, 40, 110);
      this.nowZS = canvas1.toDataURL("image/png");

      this.qrcode1 = true;
    },
    concern() {
      if (
        this.$route.params.id == this.collector_id ||
        this.$route.params.id == this.shouchang
      ) {
        return Toast(this.$t("main.cfy"));
      }
      let token = localStorage.getItem("user_token");
      if (token) {
        this.axios
          .post("/artist/editFollow", { artist_id: this.$route.params.id })
          .then(res => {
            this.follow_status = res.data.data.follow_status;
            if (res.data.data.follow_status == 1) {
              Toast.success(this.$t("main.followSuc"), 2000);
            } else {
              Toast(this.$t("main.cancelSuc"));
            }
          });
      } else {
        this.$refs.privacy.showFlag();
      }
    }
  }
};
</script>

<style scoped>
.out {
  overflow-x: hidden;
  box-sizing: border-box;
  min-height: 100vh;
  height: 100vh;
  overflow: auto;
  position: relative;
}
.pr_top {
  width: 100%;
  height: 1.3rem;
  background: url(../assets/img/wzw_top_bg-min.jpg) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.pr_pr {
  width: 7.5rem;
  height: 0.3rem;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(229, 229, 229, 0.8) 100%
  );
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0 0.3rem;
  color: #472400;
  font-size: 0.2rem;
  transform: scale(0.5);
  transform-origin: 0 100%;
}
.pr_pr img {
  width: 0.22rem;
  height: 0.18rem;
  margin-right: 0.06rem;
}
.pr_teacher{
    background: url(../assets/img/wzw_master_bg-min.png) no-repeat;
    background-size: 3.75rem 2.17rem;
    background-color: #fff;
    background-position: top center;
    box-sizing: border-box;
    padding: 0 0.15rem;
}
.pr_teacher_top{
    display: flex;
}
.pr_tt_avatar{
    position: relative;
    top: -0.32rem;
    width: 0.88rem;
    height: 0.88rem;
    box-sizing: border-box;
    padding: 0.05rem;
    background: url(../assets/img/data_avatar_bg.png) no-repeat;
    background-size: 100%;
    margin-right: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pr_tt_avatar div{
  width: 0.77rem;
  height: 0.77rem;
  border-radius: 50%;
  overflow: hidden;
}
.pr_tt_avatar img {
    width: 100%;
    object-fit: cover;
}
.pr_tt_info{
    margin-top: 0.1rem;
}
.pr_tt_info > div:nth-child(1){
    font-size: 0.18rem;
    color: #000000;
}
.pr_tt_info > div:nth-child(2){
    font-size: 0.14rem;
    color: #000000;
}
.pr_tt_follow{
    margin-top: 0.22rem;
    width: 0.52rem;
    height: 0.25rem;
    margin-left: auto;
    background: url(../assets/img/wzw_follow.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.12rem;
}
.pr_show_more{
    margin-top: 0.15rem;
    font-size: 0.12rem;
    color: #0075f4;
    margin-bottom: 0.15rem;
}
.pr_t_action{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 0.2rem;
}
.icon-logo{
    display: flex;
    align-items: center;
}
.icon-logo > div{
    display: flex;
    align-items: center;
    font-size: 0.12rem;
}
.icon-logo img{
    filter: grayscale(1);
    width: 0.16rem;
    height: 0.16rem;
    margin-right: 0.08rem;
}
.pr_item{
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.15rem;
    margin-top: 0.1rem;
    background: #fff;
}
.pr_t_honor{
    width: 100%;
    min-height: 1.13rem;
    background: url(../assets/img/wzw_honor_bg-min.jpg) no-repeat;
    background-size: 100% auto;
    box-sizing: border-box;
    border-radius: 0.03rem;
    overflow: hidden;
    padding: 0.15rem;

}
.pr_t_h_top{
    display: flex;
    align-items: center;
    font-size: 0.15rem;
    color: #472400;
    font-weight: bold;
}
.pr_t_h_top img{
    width: 0.125rem;
    height: 0.14rem;
    margin-right: 0.07rem;
}
.pr_nav{
    display: flex;
    justify-content: center;
}
.pr_nav > div{
    box-sizing: border-box;
    padding: 0 0.22rem;
    height: 0.45rem;
    display: flex;
    align-items: center;
    font-size: 0.14rem;
    color: #626262;
    position: relative;
}
.pr_nav > div.active{
    color: #000000;
    font-weight: bold;
}
.pr_nav > div.active::after{
    content: "";
    position: absolute;
    bottom: 0;
    width: 0.33rem;
    height: 0.03rem;
    background: #000;
    border-radius: 0.015rem;
    left: 50%;
    transform: translateX(-50%);
}
.pr_new_item{
    display: flex;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
}
.pr_new_item img{
  position: relative;
  left: -0.15rem;
  width: 0.55rem;
  height: 0.9rem;
}
.pr_new_item_info{
  height: 0.9rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0.1rem 0;
}
.pr_new_item_info > div:nth-child(1){
  font-size: 0.15rem;
  color: #333333;
}
.pr_new_item_info > div:nth-child(2){
  font-size: 0.12rem;
  color: #626262;
}
.pr_good_item{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 0.1rem;
}
.pr_good_item_i{
  width: 1.6rem;
  margin-top: 0.1rem;
}
.pr_good_item_i img{
  width: 1.6rem;
  height: 2.4rem;
  object-fit: cover;
}
.pr_good_item_i div{
  width: 100%;
  margin-top: 0.05rem;
  font-size: 0.15rem;
  color: #333333;
}
.pr_good_item_i > div:nth-child(2){
  height: 0.4rem;
  line-height: 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pr_good_item_i > div:nth-child(3){
  font-size: 0.12rem;
}
.pr_good_item_i > div:nth-child(4){
  font-size: 0.14rem;
  color:#333333;
  line-height: 0.2rem;
  height: 0.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.noMore {
  overflow-y: hidden !important;

  text-overflow: ellipsis;

  -webkit-box-orient: vertical;

  display: -webkit-box;

  -webkit-line-clamp: 2;

  -webkit-box-flex: 2;
}
.pr_t_h_h{
  max-height: 2.9rem;
  overflow-y: auto;
   font-size: 0.14rem;
}
.showMore {
  font-size: 0.12rem;
  color: #626262 !important;
  display: flex;
  justify-content: center;
  margin-top: 0.2rem;
 
}
.text_bottom {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.15rem;
  color: #929292;
  font-size: 0.11rem;
  text-align: center;
  padding-bottom: 0.6rem;
  padding-top: 0.1rem;
  background: #f0f0f0;
}
.report {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.3rem;
  color: #9A2A25;
  font-size: 0.14rem;
  filter: brightness(0.1);
}

.report img {
  width: 0.14rem;
  height: 0.14rem;
  margin-right: 0.1rem;
  vertical-align: top;
  font-size: 0;
}
.the_end{
  width: 100%;
  margin: 0.15rem 0;
  font-size: 0.12rem;
  color: #626262;
  display: flex;
  justify-content: center;
}
.pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.4);
  /* display: none; */
}
.share_pop{
  background: rgba(0, 0, 0, 0.7);
}

.share_top{
  position: absolute;
  left:1rem;
  top:0.2rem
}
.share_top img{
  width: 2.48rem;
  object-fit: contain;
}

.share_bottom{
  position: absolute;
  bottom:0.6rem;
  left:50%;
  transform: translateX(-50%);
}
.share_bottom img{
  width: 1.825rem;
  object-fit: contain;
}
.pop-cy-rz .pop-tishi{
  color: white;
  margin-top: 0.2rem;
  font-size: 0.14rem;
}
.pop-cy-rz .pop-tishi > img{
  width: 0.14rem;
  vertical-align: middle;
  margin-right: 0.06rem;
}
.pop-qrcode {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: url(../assets/img/longtap.png) no-repeat;
  background-size: 100% 100%; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pop-qrcode > div:nth-child(1){
  width: 2.5rem;
}
.pop-qrcode img {
  width: 2.5rem;
  object-fit: contain;
}
.header {
  display: flex;
  justify-content: space-between;

  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 0.15rem;
  padding: 0.05rem 0.15rem;
  align-items: center;
  background: #fff;
}

.cishu {
  padding-bottom: 0.15rem;
  color: #082958;
  display: flex;
  align-items: center;
  font-size: 0.15rem;
}

.cishu img {
  display: block;
  width: 0.13rem;
  height: 0.13rem;
  margin-right: 0.1rem;
}

.Title {
  font-size: 0.18rem;
  position: relative;
}


.Chinese {
  color: #1b6a21;

  left: 0;
  position: relative;
  text-align: left;
}

.Chinese .count {
  margin-left: 0.5rem;
}

.right-icon {
  width: 1rem;
  height: 0.39rem;
}

.right-icon img {
  display: block;
  width: 100%;
  height: 100%;
}


</style>
